// Override default variables before the import
@import 'vars.scss';

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/css/all';
@import '~@coreui/icons/css/all.css';
@import '~@coreui/coreui/scss/coreui';
@import 'layout.scss';
@import 'components/buttons.scss';
@import 'components/modal.scss';
@import 'components/loading.scss';
@import 'components/form.scss';
@import 'components/toast.scss';
@import 'components/progress.scss';
@import 'components/font.scss';
@import 'components/grid.scss';
@import 'components/auth.scss';
